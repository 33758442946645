.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  label {
    display: block;
    padding: 5px 0;
    p {
      margin: 0;
    }
  }
}
.error {
  color: darkred;
}
