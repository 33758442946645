.selected {
  background: lightBlue !important;
}
#roundList {
  position: absolute;
  font-size: 11px;
  width: 400px;
  // width: auto !important;
  border: solid 1px #ccc;
  min-width: auto !important;
  background: #fff;
  margin-top: 0.5em;
  display: none;
  &.show {
    display: block;
  }
}
#roundList table {
  margin: 0;
}
#roundList th {
  border-bottom: solid 1px #ccc;
}
#roundList tr {
  cursor: pointer;
}
#roundList tr:hover {
  background: #eee;
}
#roundList .goBtn {
  text-align: center;
  padding: 0.5em;
}
#tableWrapper {
  max-height: 200px;
  overflow-y: scroll;
}
.goBtn {
  display: none;
}
.closeList {
  cursor: pointer;
  text-align: center;
  padding: 0.25em;
  background: #555;
  color: #fff;
}
.load-rounds {
  cursor: pointer;
  text-align: center;
  padding: 0.25em;
  background: darkgreen;
  color: #fff;
}
