@import "~bootstrap/scss/bootstrap";
@import "./mixins.scss";

.container,
.container-md,
.container-sm {
  max-width: 100%;
}

.content {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  @include sm {
    padding: 20px;
  }
}

.clickable {
  color: #047bfe;
  cursor: pointer;
  text-decoration: underline;

  &.active {
    font-weight: bold;
    text-decoration: none;
  }
}

.font-sm {
  font-size: 0.85em;
}
.title {
  text-transform: capitalize;
}

.align-items-right {
  display: flex;
  justify-content: flex-end;
}

.push-right {
  margin-left: auto;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.3em;
}
h4 {
  font-size: 1.2em;
}
h5 {
  font-size: 1.1em;
}
