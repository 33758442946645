/* ----- MEDIA QUERIES ----- */
// $screen-min-width: 420px;
// $screen-xsmall: 565px;
// $screen-small: 768px;
// $screen-medium: 992px;
// $screen-large: 1200px;
// $screen-xlarge: 1920px;

$screen-min-width: 391px;
$screen-xsmall: 391px;
$screen-small: 391px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-xlarge: 1200px;

@mixin tiny {
  @media (min-width: #{$screen-min-width}) {
    @content;
  }
}
@mixin xs {
  @media (min-width: #{$screen-xsmall}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$screen-small}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-medium}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-large}) {
    @content;
  }
}
// Custom devices
@mixin sz($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}
